




































































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import moment from 'moment'

import DataCard from '@/partials/components/DataCard.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import ActivitiesGraph from '@/partials/components/Service/ActivitiesGraph.vue'

@Component({
  components: {
    DataCard,
    ActivitiesGraph
  }
})
export default class ResidentDiary extends Mixins(PermissionsMixin) {
  windowWidth = window.innerWidth

  created () {
    this.getMyDiary()
  }

  myDiary: any = {}
  getMyDiary () {
    axios.get(`service/${this.$route.params.serviceUserId}/diary`)
      .then((response) => {
        const activities = Object.values(camelCaseKeys(response.data.data, { deep: true }))

        activities.forEach((activity: any) => {
          const year = moment(activity.performedAt).year()
          const month = moment(activity.performedAt).format('MMMM')

          if (!this.myDiary[year]) {
            this.myDiary[year] = {}
          }
          if (!this.myDiary[year][month]) {
            this.myDiary[year][month] = []
          }

          this.myDiary[year][month].push(activity)
        })

        this.myDiary = Object.entries(this.myDiary)
      })
  }

  search () {
    console.log('busca')
  }

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }

  onResize () {
    this.windowWidth = window.innerWidth
  }
}
