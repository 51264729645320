



























import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class ActivitiesGraph extends Vue {
  @Prop({ required: true }) serviceUserId!: string

  created () {
    axios.get(`/service/${this.serviceUserId}/diary/report`)
      .then(response => {
        const activitiesGraph = response.data.data

        this.activitiesDonut.fulfillment = activitiesGraph.fulfillment
        this.activitiesDonut.data = activitiesGraph.data
        this.activitiesDonut.options.donut.center.label = activitiesGraph.title
      })
  }

  activitiesDonut = {
    fulfillment: { days: 0, percent: 0 },
    data: [],
    options: {
      resizable: true,
      donut: {
        center: {
          label: ''
        },
        alignment: 'center'
      },
      legend: {
        alignment: 'center'
      },
      height: '400px'
    }
  }
}
